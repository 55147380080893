import { gql } from '@apollo/client'

export const getProfile = gql`
  query Profile($id: uuid!, $date: timestamptz) {
    user(where: { id: { _eq: $id } }, limit: 1) {
      id
      title
      name: given_name
      surname: family_name
      email
      phoneNumber: contact_number
      points
      account: stripe_id
      createdAt: created_at
      updatedAt: updated_at
      links(limit: 1) {
      	id
        link
      	referrals {
          id
	        value
        	status
  	     	createdAt: created_at
        }
      }
      forms: form_responses {
        user
        id
        form
        complete
        responses
        asset
        createdAt
        updatedAt
      }
      recommendations(order_by: { created_at: desc }, limit: 5) {
        id
        product: productByProduct {
          id
          title
          metadata: productMetadata {
            path
          }
          media {
            id
            asset: assetByAsset {
              id
              url
              type: assetType {
                value
              }
              updatedAt
              createdAt
            }
          }
          pricing {
            id
            createdAt: created_at
            updatedAt: updated_at
            data: pricingByPricing {
              id
              label
              price
              type: pricing_type {
                value: type
              }
              options {
                id
                data: pricingByOption {
                  label
                  price
                }
              }
            }
          }
        }
        service: serviceByService {
          id
          title
          metadata: serviceMetadata {
            path
          }
          media {
            id
            asset: assetByAsset {
              id
              url
              type: assetType {
                value
              }
              updatedAt
              createdAt
            }
          }
          pricing {
            id
            data: pricingByPricing {
              id
              label
              price
              attributes
              discount
              was
              createdAt: created_at
              updatedAt: updated_at
              type: pricing_type {
                value: type
              }
              options {
                id
                data: pricingByOption {
                  id
                  attributes
                  label
                  price
                  was
                  discount
                  createdAt: created_at
                  updatedAt: updated_at
                }
              }
            }
          }
        }
        appointment: appointment_id
        employee: user_id
        createdAt: created_at
      }
      appointments: appointments(
        where: {
          _and: [
            { status: { _neq: deleted } }
            { entities: {
              status: { _neq: deleted }
            } }
          ]
        }
      ) {
        id
        metadata
        status: appointmentStatus {
          value: status
        }
        payments {
          data: paymentByPayment {
            id
            amount
            discount
            createdAt: created_at
          }
        }
        entities(
          order_by: { start: asc_nulls_last }
          where: { status: { _neq: deleted } }
        ) {
          id
          appointment
          start
          end
          employee
          service: serviceByService {
            id
            title
          }
          option: pricing {
            id
            label
            attributes
            discount
            price
            was
          }
          subOption: pricingByPricingSubOption {
            id
            label
            price
            discount
            was
            attributes
          }
          status: appointment_status {
            value: status
          }
          createdAt: created_at
          updatedAt: updated_at
        }
        createdAt: created_at
        updatedAt: updated_at
      }
    }
  }
`

export const addContactNumber = gql`
  mutation AddContactNumber($id: uuid!, $number: String) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { contact_number: $number }
    ) {
      id
      contact_number
    }
  }
`

export const addAddress = gql`
  mutation InsertAddress($address: user_address_insert_input!) {
    insert_user_address_one(object: $address) {
      id
    }
  }
`

export const userPayments = gql`
  query userPayments {
    paymentMethods {
      id
      amount
      amount_capturable
      amount_received
      canceled_at
      cancellation_reason
      capture_method
      charges
      client_secret
      confirmation_method
      created
      customer
      description
      last_payment_error
      next_action
      object
      payment_method
      payment_method_types
      review
      setup_future_usage
      status
    }
  }
`

export const updateUserProperty = gql`
  mutation UpdateUserProperty($id: uuid!, $user: user_set_input!) {
    update_user(where: { id: { _eq: $id } }, _set: $user) {
      affected_rows
      returning {
        id
        title
        name: given_name
        surname: family_name
        email
        gender
        birthday
        phoneNumber: contact_number
        avatar
        points
        account: stripe_id
        createdAt: created_at
        updatedAt: updated_at
      }
    }
  }
`;

export const captureReferral = gql`
  mutation CaptureReferral($linkId: uuid!) {
    insert_referral_one(object: {
      link_id: $linkId
    }) {
      id
    }
  }
`
