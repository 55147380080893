import { gql } from '@apollo/client'

export const appointmentById = gql`
  query AppointmentById($id: uuid!) {
    appointment: appointment_by_pk(id: $id) {
      id
      metadata
      status: appointmentStatus {
        value: status
      }
      client: userByUser {
        id
        title
        name: given_name
        surname: family_name
        fullName: full_name
        points
        avatar
        phoneNumber: contact_number
        email
        customerId: stripe_id
        createdAt: created_at
      }
      services: entities(
        order_by: { start: asc }
        where: { status: { _neq: deleted } }
      ) {
        id
        start
        end
        employee
        service: serviceByService {
          id
          title
          subtitle
          content
          attributes
          options
          createdAt: created_at
          updatedAt: updated_at
          media {
            id
            asset: assetByAsset {
              id
              url
              type: assetType {
                value: value
              }
              updatedAt
              createdAt
            }
          }
          category: categoryByCategory {
            id
            name
          }
          metadata: serviceMetadata {
            id
            description
            path
            title
            createdAt: created_at
            updatedAt: updated_at
          }
          pricing {
            data: pricingByPricing {
              id
              label
              price
              attributes
              discount
              was
              createdAt: created_at
              updatedAt: updated_at
              type: pricing_type {
                value: type
              }
              options {
                data: pricingByOption {
                  id
                  attributes
                  label
                  price
                  was
                  discount
                  createdAt: created_at
                  updatedAt: updated_at
                }
              }
            }
          }
          tags {
            id
            tag: tagByTag {
              name
            }
          }
        }
        option: pricing {
          id
          label
          price
          attributes
          type
          discount
        }
        subOption: pricingByPricingSubOption {
          id
          label
          price
          attributes
          type
          discount
        }
        status: appointment_status {
          value: status
        }
        createdAt: created_at
        updatedAt: updated_at
        resource_id
      }

      payments {
        id
        data: paymentByPayment {
          id
          transaction_id
          discount
          amount
          method
          createdAt: created_at
        }
      }
      photos: appointment_assets {
        id
        asset: assetByAsset {
          id
          url
          level
          properties: props
          type: assetType {
            value
          }
          createdAt
          updatedAt
        }
      }
      products {
        id
        price
        quantity
        product {
          id
          title
          media(where: { assetByAsset: { type: { _eq: IMAGE } } }) {
            data: assetByAsset {
              id
              type
              url
            }
          }
        }
        employee {
          id
          name: given_name
        }
        createdAt: created_at
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`

// export const conditionById = gql`
//   query ConditionById($path: String!) {
//     content: content(where: { contentMetadata: { path: { _ilike: $path } } }) {
//       id
//       title
//       content
//       category: categoryByCategory {
//         id
//         name
//       }
//       metadata: contentMetadata {
//         id
//         title
//         description
//         path
//       }
//       createdAt: created_at
//       updatedAt: updated_at
//     }
//   }
// `

export const newAppointment = gql`
  mutation NewAppointment($data: [appointment_insert_input!]!) {
    insert_appointment(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const newOrder = gql`
  mutation InsertOrder($data: [order_insert_input!]!) {
    order: insert_order(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const updateOrder = gql`
  mutation UpdateOrder($id: uuid!, $data: jsonb!) {
    order: update_order_by_pk(pk_columns: { id: $id }, _set: { data: $data }) {
      id
    }
  }
`

export const getOrder = gql`
  query GetOrder($id: uuid!) {
    order: order_by_pk(id: $id) {
      id
      user
      data
    }
  }
`

export const getAppointmentEntityById = gql`
  query getAppointmentEntityById($id: uuid!) {
    entity: appointment_entity_by_pk(id: $id) {
      id
      start
      end
      employee
      appointment
      appointmentByAppointment {
        userId: user
        user: userByUser {
          id
          given_name
          family_name
        }
      }
      resource: resource_id
      option: pricing {
        id
        label
        price
      }
      subOption: pricingByPricingSubOption {
        id
        label
        price
      }
      service: serviceByService {
        id
        title
      }
      who
      user {
        given_name
        family_name
      }
      organization_member {
        user {
          given_name
          family_name
        }
      }
    }
  }
`

export const updateAppointmentEntityById = gql`
  mutation UpdateAppointmentEntity(
    $id: uuid!
    $data: appointment_entity_set_input!
  ) {
    entity: update_appointment_entity_by_pk(
      pk_columns: { id: $id }
      _set: $data
    ) {
      id
      start
      appointment
      end
      employee
      resource: resource_id
      option: pricing {
        id
        label
        price
      }
      subOption: pricingByPricingSubOption {
        id
        label
        price
      }
      service: serviceByService {
        id
        title
      }
      status: appointment_status {
        value: status
      }
    }
  }
`

export const appointmentFeedback = gql`
  query getFeedback($id: uuid!) {
    feedback(
      order_by: { created_at: desc }
      limit: 1
      where: { appointment: { _eq: $id } }
    ) {
      id
      content
      employeeData {
        id
        name: given_name
      }
      recommendations {
        id
        product: productByProduct {
          id
          title
          metadata: productMetadata {
            path
          }
          media {
            id
            asset: assetByAsset {
              id
              url
              type: assetType {
                value
              }
              updatedAt
              createdAt
            }
          }
          pricing {
            id
            createdAt: created_at
            updatedAt: updated_at
            data: pricingByPricing {
              id
              label
              price
              type: pricing_type {
                value: type
              }
              options {
                id
                data: pricingByOption {
                  label
                  price
                }
              }
            }
          }
        }
        service: serviceByService {
          id
          title
          metadata: serviceMetadata {
            path
          }
          media {
            id
            asset: assetByAsset {
              id
              url
              type: assetType {
                value
              }
              updatedAt
              createdAt
            }
          }
          pricing {
            id
            data: pricingByPricing {
              id
              label
              price
              attributes
              discount
              was
              createdAt: created_at
              updatedAt: updated_at
              type: pricing_type {
                value: type
              }
              options {
                id
                data: pricingByOption {
                  id
                  attributes
                  label
                  price
                  was
                  discount
                  createdAt: created_at
                  updatedAt: updated_at
                }
              }
            }
          }
        }
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`
