import { gql } from '@apollo/client'

export const getTeam = gql`
  query Team {
    team: organization_member(where: { _and: [{ active: { _eq: true } }] }) {
    	avatar: member_assets(limit: 1) {
        id
      	url
      }
      user {
        id
        name: given_name
        surname: family_name
        # avatar: user_assets(limit: 1) {
        #   asset: assetByAsset {
        #     id
        #     url
        #   }
        # }
        services {
          id: service
        }
      }
    }
  }
`

export const getAvailibility = gql`
  query Availibility(
    $start: date!
    $end: date!
    $eStart: timestamptz!
    $eEnd: timestamptz!
  ) {
    availibility: schedule(
      where: {
        _and: [
          { available: { _eq: true } }
          { date: { _gt: $start } }
          { date: { _lte: $end } }
        ]
      }
      order_by: { date: asc }
    ) {
      id
      date
      start
      end
      available
      week: week_number
      day: day_of_week
      member: organization_member {
        id: member
      }
    }
    appointments: appointment_entity(
      where: {
        _and: [
          { start: { _gt: $eStart } }
          { end: { _lte: $eEnd } }
          { status: { _neq: cancelled } }
          { status: { _neq: refunded } }
          { status: { _neq: no_show } }
          { status: { _neq: completed } }
          { status: { _neq: deleted } }
        ]
      }
    ) {
      id
      start
      end
      employee
    }
    blocks: appointment_block(
      where: { _and: [{ start: { _gte: $eStart } }, { end: { _lte: $eEnd } }] }
    ) {
      id
      start
      end
      resource: resource_id
      employee: user
    }
    resource {
      id
      name
      services {
        id
        service {
          title
        }
        options
        service_id
      }
      entities(
        order_by: {
          start: asc
        }
        where: {
          _and: [
            { start: { _gte: $eStart } }
            { end: { _lte: $eEnd } }
          ]
        }
      ) {
        start
        end
        resource_id
      }
    }
  }
`

export const getReferralLink = gql`
  query ReferralLink($link: String!) {
    link: referral_link(where: {
      link: { _eq: $link }
    }) {
      id
      user_id
      link
      createdAt: created_at
    }
  }
`
