import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

export default function ApiClient (token?: string): ApolloClient<NormalizedCacheObject> {
  const authLink = setContext((_, { headers }) => {
    const newHeaders: { [key: string] : any } = {
      'x-hasura-organization-id': process.env.NEXT_PUBLIC_ORGANIZATION_ID,
      ...(
        process.env.NEXT_PUBLIC_IMPERSONATE && process.env.NEXT_PUBLIC_IMPERSONATE === 'true'
        ? {
          'x-hasura-organization-id': process.env.NEXT_PUBLIC_ORGANIZATION_ID,
          "x-hasura-admin-secret": process.env.NEXT_PUBLIC_IMP_SECRET, 
          "x-hasura-role-id": process.env.NEXT_PUBLIC_IMP_ROLE,
          "x-hasura-user-id": process.env.NEXT_PUBLIC_IMP_ID
        }
        : {}
      )
    }
    if (token) {
      newHeaders.authorization = `Bearer ${token}`;
    }

    return {
      headers: {
        ...headers,
        ...newHeaders
      }
    }
  });

  const httpLink = new HttpLink({
    credentials: 'include',
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}