export const Main = () => (
  <div className='invisible h-0 overflow-hidden sr-only'>
    <h2>Elite Aesthetics, Skin, Laser Hair Removal & Beauty at Soluna London</h2>
    <p>
      Soluna London is proud to serve our loving customers by delivering advanced industry leading services. From dermal fillers, laser hair removal, Fat Freezing - Cryolipolysis and much much more.
    </p>
    <p>Soluna London offers free no obliigation consultations for you to be able to get advice direct from the experts</p>
    <h3>Unbeatable on price and value</h3>
    <p>We believe that what you pay should be less than what you receive. This is why we strive for unparelled levels of service with clinical results</p>
    <h4>Trusted team that delivers proven results</h4>
    <p>Whether you need to shape up with our advanced body contouring services, save time waxing and shaviing with medical grade laser hair removal or wish to rehydrate and rejuvenate your skin - Soluna London has the services and expertise to bring you complete satisfaction</p>
    <a href='/laser-hair-removal/' className='btn btn-primary'>Learn More</a>
    <a href='/laser-hair-removal-for-dark-skin/' className='btn btn-primary'>Laser Hair Removal for dark skin at Soluna London</a>
    <a href='/laser-hair-removal-for-asian-skin/' className='btn btn-primary'>Laser Hair Removal for asian skin at Soluna London</a>
    <a href='/laser-hair-removal-for-indian-skin/' className='btn btn-primary'>Laser Hair Removal for indian skin at Soluna London</a>
  </div>
)

export const LaserHair = () => (
  <div className='invisible h-0 overflow-hidden sr-only'>
    <h3>Laser Hair Removal At Unbeatable Prices</h3>
    <p>Our industry leading lasers use concentrated energy to target hair follicles in unwanted areas to slow and eliminate the hair growth cycle, leaving your skin feeling silky smooth, with lasting results.</p>
    <p>Our medical grade laser machines concentrate a beam of light onto the hair follicle. The light is then absorbed by the hair’s pigment causing controlled damage to the hair follicle, preventing it from re-growing in the future.</p>
    <p>Our trained therapists are equipped with industry leading medical grade machines, designed to treat all skin types and deliver the best results possible.</p>
    <h4>Hair Removal Results That Speak For Themselves</h4>
    <p>You can think of it as waxing or shaving, but with more permanent results. If silky smooth skin wasn’t enough, laser hair removal will also save you on the long-term costs associated with waxing and shaving!</p>
    <h5>Say goodbye to waxing and shaving!</h5>
    <a href='/laser-hair-removal/' className='btn btn-primary'>Learn More</a>
    <a href='/laser-hair-removal-for-dark-skin/' className='btn btn-primary'>Laser Hair Removal for dark skin at Soluna London</a>
    <a href='/laser-hair-removal-for-asian-skin/' className='btn btn-primary'>Laser Hair Removal for asian skin at Soluna London</a>
    <a href='/laser-hair-removal-for-indian-skin/' className='btn btn-primary'>Laser Hair Removal for indian skin at Soluna London</a>
  </div>
)