import * as Sentry from '@sentry/browser'
import { useRouter } from 'next/router'
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import {
  ApolloClient,
  InMemoryCache,
  QueryLazyOptions,
  useApolloClient,
  useLazyQuery,
  useQuery,
} from '@apollo/client'
import { setHours, addMinutes, formatISO, isSameDay } from 'date-fns'
import {
  PaymentIntent,
  PaymentMethodCreateParams,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeElements,
  StripeError,
} from '@stripe/stripe-js'
import { CardNumberElement } from '@stripe/react-stripe-js'

import {
  Service,
  Product,
  Team,
  TeamMember,
  Availibility,
  AppointmentSlots,
  NewAppointment,
  AppointmentEntity,
  Discount,
} from '../interfaces'

import { useSession } from './session'

import { newAppointment } from '../queries/appointments'
import { addAddress, addContactNumber } from '../queries/user'
import { getAvailibility, getTeam } from '../queries/org'

import generateRange from '../utils/range'
import { intercomEvent } from '../utils/analytics'
import { deposit } from '../utils/pricing'
import { Conversion, recordConversion } from '../utils/conversions'
import { klayAction } from '../utils/formats'
import { BASKET_KEY } from '../utils/keys'

const persist = (data: BasketItems) => {
  localStorage.setItem(BASKET_KEY, JSON.stringify(data))
}

const retrieve = (): BasketItems => {
  const items = localStorage.getItem(BASKET_KEY)
  if (items) {
    return JSON.parse(items)
  }
  return { services: [], products: [] }
}

export interface ServiceBasketItem {
  quantity: number
  option: string
  subOption?: string
  item: Service
  date: string | null
  time: string | null
  member?: string | null
  location?: string | null
  resource?: string | null
  discountValue?: number | null
  canBeDiscounted?: boolean | null
}

export interface ProductBasketItem {
  quantity: number
  option: string
  item: Product
  subOption?: string
}

export interface BasketItems {
  services: ServiceBasketItem[]
  products: ProductBasketItem[]
}

interface State {
  show: boolean
  notify: boolean
  total: number
  productTotal: number
  serviceTotal: number
  minimumPayableToday: number
  duration?: number
  containsProducts: boolean
  containsServices: boolean
  items: BasketItems
  availibility?: AppointmentSlots
  processingPayment?: boolean
  paymentResult: PaymentIntent | null
  paymentError: StripeError | false | null
  discount: Discount | null
  discountTotal: number
  discountValue: number
  shipping: any
  open: boolean
  buy3get2: boolean
}

interface BasketContextValue extends State {
  addItem: (
    service?: Service,
    product?: Product,
    free?: boolean,
    option?: string,
    subOption?: string,
    suppress?: boolean
  ) => void
  setOpen: (open: boolean) => void
  removeItem: (id: string) => void
  setOption: (id: string, index?: number) => void
  setSubOption: (option: string, id: string, index?: number) => void
  displayBasket: () => void
  empty: () => void
  membersForServices: () => TeamMember[]
  loadAvailibility: (
    options?: QueryLazyOptions<Record<string, any>> | undefined
  ) => void
  hideNotify: () => void
  setLoading: () => void
  setDuration: (i: Number) => void
  setServiceItemDetails: (
    id: string,
    data: ServiceBasketItem,
    index?: number
  ) => void
  setProductQuantity: (id: string, quantity: number) => void
  setDiscount: (discount: Discount) => void
  setShipping: (shipping: any) => void
  handleSubmission: (
    checkoutState: any,
    stripe: Stripe,
    elements: StripeElements,
    newToken: string | null,
    newProfileId: null | string,
    ev?: PaymentRequestPaymentMethodEvent
  ) => Promise<boolean>
  team: TeamMember[] | undefined
  availibilityLoading: boolean
}

export interface BasketItem {
  item: Service | Product
  quantity: number
  option: string
  subOption?: string
}

const barred = [
  'Dermal Fillers',
  'Skin Boosters',
  'IV Therapy & Booster Shots',
  'Packages'
]
const basketContents = (
  { services, products }: BasketItems,
  discount?: Discount,
  shipping?: any
) => {
  const containsProducts = products && products.length > 0
  const containsServices = services && services.length > 0

  function validateServices(allServices) {
    let newObj = []
    for (var key in allServices) {
      if (allServices[key] === true) newObj.push(key)
    }
    return newObj
  }

  const singleServices = services.filter((service) => {
    const attr = service.item.pricing.find((p) => p.data.id === service.option)
    const subAttr = service.subOption
      ? attr.data.options.find((p) => p.data.id === service.subOption)
      : null

    const sessions =
      subAttr && subAttr.data.attributes
        ? Number(subAttr.data.attributes.sessions)
        : attr && attr.data.attributes
        ? Number(attr.data.attributes.sessions)
        : null

    return sessions === 1
  })

  let discountValue = 0
  let discountTotal = 0
  let lowestUnitAmount = Number.MAX_SAFE_INTEGER
  let lowestUnitService = null
  const discountedPrices = new Set()
  const discountedServices = new Set()
  let buy3get2 = false
  let hasBarredService = false

  if (singleServices.length >= 3) {
    console.log(singleServices, 'singleServices')
    singleServices.forEach((service) => {
      if (barred.includes(service.item.category.name)) {
        hasBarredService = true
        // buy3get2 = false
        console.log('BARRED', service)
      }
      const price = service.item.pricing.find(
        (y) => y.data.id === service.option
      )
      const subPrice = service.subOption
        ? price.data.options.find((y) => y.data.id === service.subOption)
        : null

      const final_price = subPrice ? subPrice.data.price : price.data.price

      if (final_price < lowestUnitAmount) {
        lowestUnitAmount = final_price
        lowestUnitService = service.option
      }
    })

    singleServices.forEach((service) => {
      if (service.option === lowestUnitService) {
        const price = service.item.pricing.find(
          (y) => y.data.id === service.option
        )
        const subPrice = service.subOption
          ? price.data.options.find((y) => y.data.id === service.subOption)
          : null

        const final_price = subPrice ? subPrice.data.price : price.data.price

        discountedServices.clear()
        discountedServices.add(service.item.id)
        service.discountValue = final_price
        service.canBeDiscounted = true
        // buy3get2 = !hasBarredService ? true : false
      } else {
        service.discountValue = 0
        service.canBeDiscounted = false
      }
    })
  }

  console.log('hasBarredService', hasBarredService)
  const { duration: serviceDuration, price: serviceTotal } =
    services &&
    services.reduce(
      (a, b, currentIndex) => {
        const item = b.item.pricing.find((x) => x.data.id === b.option)!
        const sub = b.subOption
          ? item.data.options.find((x) => x.data.id === b.subOption)!
          : null

        if (!item) {
          return a
        }

        const target = sub || item
        const price = target.data.price
        const duration =
          target.data.attributes && target.data.attributes.duration
            ? Number(target.data.attributes.duration)
            : 30

        let final_amount = price

        if (
          services.length >= 3 &&
          price === lowestUnitAmount &&
          !discountedPrices.has(price) &&
          !discount
          // !hasBarredService
        ) {
          final_amount = 0
          discountedPrices.add(price)
        }

        return {
          duration: currentIndex < 2 ? a.duration + duration : a.duration,
          price: a.price + final_amount,
        }
      },
      {
        duration: 0,
        price: 0,
      }
    )

  let productTotal =
    products &&
    products.reduce((a, b) => {
      const item = b.item.pricing.find((x) => x.data.id === b.option)!
      return a + item.data.price * b.quantity
    }, 0)

  const shippingCost = shipping ? shipping.cost : 0

  const total = serviceTotal + productTotal + shippingCost

  if (discount && discount.type === 'percent') {
    buy3get2 = false
    const validServices = validateServices(discount.options)
    services?.forEach((service) => {
      const servicIsValid = validServices.includes(service.item.id)

      service.canBeDiscounted = false

      if (servicIsValid) {
        let targetPrice = 0
        const price = service.item.pricing.find(
          (pricing) => pricing.data.id === service.option
        ).data.price

        targetPrice = price

        if (service.subOption) {
          const subOption = service.item.pricing
            .find((pricing) => pricing.data.id === service.option)
            .data.options.find((option) => option.data.id === service.subOption)

          targetPrice = subOption.data.price
        }

        const discountAmount = discount.value / 100
        const serviceDiscount = discountAmount * targetPrice
        discountValue += serviceDiscount

        service.discountValue = serviceDiscount
        service.canBeDiscounted = true
      }
    })
    discountTotal = Math.round(total - Math.round(discountValue))
  }

  let ineligibleItems = false

  if (discount && discount.type === 'pound') {
    buy3get2 = false
    const validServices = validateServices(discount.options)
    let numberOfValidServices = 0

    services?.forEach((service) => {
      const servicIsValid = validServices.includes(service.item.id)

      service.canBeDiscounted = false

      if (servicIsValid) {
        numberOfValidServices++
        service.discountValue = 0
        service.canBeDiscounted = true
      }
    })

    if (numberOfValidServices === services.length) {
      discountValue = discount.value
      discountTotal = Math.round(total - discount.value)
    } else {
      ineligibleItems = true
    }
  }

  let minimumPayableToday = total === 0 ? 0 : deposit(total)

  if (!containsServices) {
    minimumPayableToday = productTotal + shippingCost
  } else if (productTotal > 0 && shippingCost > 0) {
    minimumPayableToday = minimumPayableToday + productTotal
  }

  return {
    total,
    productTotal,
    serviceTotal,
    discountTotal,
    discountValue,
    ineligibleItems,
    minimumPayableToday,
    containsProducts,
    containsServices,
    duration: serviceDuration,
    buy3get2,
  }
}

const initialState = {
  show: false,
  notify: false,
  total: 0,
  productTotal: 0,
  buy3get2: false,
  serviceTotal: 0,
  minimumPayableToday: 0,
  duration: 0,
  containsProducts: false,
  containsServices: false,
  discount: null,
  discountTotal: 0,
  discountValue: 0,
  items: {
    services: [],
    products: [],
  },
  availibility: {},
  processingPayment: false,
  paymentResult: null,
  paymentError: null,
  shipping: null,
  shippingValue: 0,
  open: false,
}

export const BasketContext = createContext(
  initialState as unknown as BasketContextValue
)

export interface API {
  children: any
}

export const Basket = ({ children }: API) => {
  const { profile, claims, sourceParams, setContext, token } = useSession()
  const [state, setState] = useState<State>(initialState)
  const client = useApolloClient()
  const router = useRouter()
  const { data: teamData } = useQuery<Team>(getTeam, {
    variables: {
      'x-hasura-user-id': token || null,
    },
  })

  const [
    loadAvailibility,
    { data: availibilityData, loading: availibilityLoading },
  ] = useLazyQuery<Availibility>(getAvailibility)

  useEffect(() => {
    const currentItems = retrieve()
    setState({
      show: false,
      open: false,
      notify: false,
      discount: null,
      discountTotal: 0,
      ...basketContents(currentItems, state.discount, state.shipping),
      items: currentItems,
      processingPayment: false,
      paymentResult: null,
      paymentError: false,
      shipping: null,
    })
  }, [])

  useEffect(() => {
    if (availibilityData?.availibility && teamData?.team) {
      const availibility: AppointmentSlots = {}
      availibilityData.availibility.forEach((record) => {
        const recordDate = new Date(record.date)
        // console.log(record)
        // @ts-ignore
        const matchingBlocks = availibilityData.blocks.filter((x) => {
          const s = new Date(x.start)
          return isSameDay(s, recordDate) && x.employee === record.member.id
        })

        const matchingAppointments =
          availibilityData &&
          // @ts-ignore
          availibilityData.appointments.filter((x) => {
            const s = new Date(x.start)
            return isSameDay(s, recordDate) && x.employee === record.member.id
          })

        const appointments = [
          ...matchingAppointments.map((x) => ({
            // @ts-ignore
            id: x.id,
            type: 'appt',
            employee: x.employee,
            start: new Date(x.start),
            end: new Date(x.end),
          })),
          ...matchingBlocks.map((x) => ({
            type: 'block',
            employee: x.employee,
            start: new Date(x.start),
            end: new Date(x.end),
          })),
        ]

        const memberDate = Date.parse(`${record.date}`)

        let ranges: Date[] = []

        const start = setHours(
          memberDate,
          record.start.split(':')[0] as unknown as number
        )

        const end = setHours(
          memberDate,
          record.end.split(':')[0] as unknown as number
        )

        ranges = generateRange(
          start,
          end,
          15,
          Number(state.duration || 0),
          appointments
        )

        if (!availibility[record.member.id] && ranges.length > 0) {
          availibility[record.member.id] = {
            [record.date]: ranges,
          }
        } else {
          if (ranges.length > 0) {
            availibility[record.member.id][record.date] = ranges
          }
        }
      })
      setState((s) => ({
        ...s,
        availibility,
      }))
    }
  }, [availibilityData, state.items, state.duration, teamData])

  useEffect(() => {
    // @ts-ignore
    if (availibilityData && availibilityData.resource) {
      // @ts-ignore
      console.log('availibilityData', availibilityData.resource)
    }
  }, [availibilityData])

  const addItem = useCallback(
    (
      service?: Service,
      product?: Product,
      free?: boolean,
      option?: string,
      subOption?: string,
      suppress: boolean = false
    ) => {
      if (!service && !product) return null

      const subOptions = service
        ? service.pricing.filter((x) => x.data.options.length > 0)
        : []

      const newItems = {
        services:
          state.items && Array.isArray(state.items.services)
            ? [...state.items.services]
            : [],
        products:
          state.items && Array.isArray(state.items.products)
            ? [...state.items.products]
            : [],
      }

      console.log('service', service)
      console.log('subOptions', subOptions)
      if (service && subOptions.length > 0) {
        const target =
          option && option
            ? option
            : service.options &&
              service.options.suppressConsultation &&
              service.pricing[service.pricing.length - 1]
            ? service.pricing[service.pricing.length - 1].data.id
            : service.pricing[0].data.id

        console.log('target', target, option, subOption)
        const options = service.pricing

        newItems.services.push({
          quantity: 1,
          option: target,
          // item: service,
          item: {
            ...service,
            pricing: options,
          },
          subOption,
          date: null,
          time: null,
          member: null,
          resource: null,
          discountValue: null,
          canBeDiscounted: null,
        })
      }
      // Else add as normal (service with subOptions)
      else if (service && subOptions.length === 0) {
        const options = service.pricing
        const target =
          option && option
            ? option
            : service.options &&
              service.options.suppressConsultation &&
              options[1]
            ? options[1].data.id
            : options[0].data.id

        newItems.services.push({
          quantity: 1,
          option: target,
          item: {
            ...service,
            pricing: options,
          },
          date: null,
          time: null,
          member: null,
          resource: null,
          discountValue: null,
          canBeDiscounted: null,
        })
      }

      if (product) {
        newItems.products.push({
          quantity: 1,
          option: product.pricing[0].data.id,
          item: product,
        })
      }

      persist(newItems)

      setState((s) => ({
        ...s,
        ...basketContents(newItems, state.discount, state.shipping),
        items: newItems,
        // show: true,
        // notify: suppress === false,
        open: true,
      }))

      document.body.dispatchEvent(
        new CustomEvent('items:update', {
          detail: newItems,
        })
      )

      try {
        const item = service || product
        const opt = option
          ? item.pricing.find((p) => p.data.id === option)
          : // @ts-ignore
          item &&
            // @ts-ignore
            item.options &&
            // @ts-ignore
            item.options.suppressConsultation &&
            item.pricing[1]
          ? item.pricing[1]
          : item.pricing[0]

        const subOpt =
          subOption && opt
            ? opt.data.options.find((o) => o.data.id === subOption)
            : null

        window.gtag &&
          window.gtag('event', 'add_to_cart', {
            items: [
              {
                currency: 'GBP',
                category: item.category.name,
                item_id: item.id,
                item_name: item.title,
                price:
                  subOpt && subOpt.data
                    ? subOpt.data.price / 100
                    : opt && opt.data
                    ? opt.data.price / 100
                    : 0,
                quantity: 1,
                ...item,
              },
            ],
            value: item!.pricing[0].data.price / 100,
            currency: 'GBP',
          })

        window.ttq &&
          window.ttq.track('AddToCart', {
            content_id: item.id,
            quantity: 1,
            content_name: item.title,
            price:
              subOpt && subOpt.data
                ? subOpt.data.price / 100
                : opt && opt.data
                ? opt.data.price / 100
                : 0,
            currency: 'GBP',
          })

        try {
          // @ts-ignore
          window.fbq &&
            // @ts-ignore
            window.fbq('track', 'AddToCart', {
              value: item!.pricing[0].data.price / 100,
              currency: 'GBP',
              content_name: item.title,
              content_type: 'product',
              contents: [
                {
                  id: item.id,
                  quantity: 1,
                },
              ],
              content_ids: item.id,
            })
        } catch (err) {
          console.log(err)
          Sentry.captureException(err)
        }

        try {
          // @ts-ignore
          window.FS &&
            // @ts-ignore
            window.FS.event('add_to_cart', {
              items: [{ name: item!.title, ...item }],
              value:
                subOpt && subOpt.data
                  ? subOpt.data.price / 100
                  : opt && opt.data
                  ? opt.data.price / 100
                  : 0,
              currency: 'GBP',
            })

          const payload = klayAction({
            service: item as Service,
            type: 'add',
            items: [...newItems.services, ...newItems.products],
          })
          // @ts-ignore
          klaviyo && klaviyo.push(['track', 'Added to Cart', payload])
        } catch (err) {
          console.log(err)
          Sentry.captureException(err)
        }

        intercomEvent('add-to-cart', {
          'page-title': document.title,
          page: window.location.pathname,
          value:
            subOpt && subOpt.data
              ? subOpt.data.price / 100
              : opt && opt.data
              ? opt.data.price / 100
              : 0,
          service: item!.title,
        })

        // @ts-ignore
        window.heap &&
          // @ts-ignore
          window.heap.track('Add to Cart', {
            items: [{ id: item!.id, name: item!.title }],
            value:
              subOpt && subOpt.data
                ? subOpt.data.price / 100
                : opt && opt.data
                ? opt.data.price / 100
                : 0,
            currency: 'GBP',
          })
      } catch (err) {
        console.log(err)
        Sentry.captureException(err)
      }
    },
    [state]
  )

  const removeItem = useCallback(
    (id: string) => {
      const newItems: BasketItems = {
        services: [...state.items.services],
        products: [...state.items.products],
      }

      const serviceIndex = newItems.services.findIndex((x) => x.item.id === id)
      const productIndex = newItems.products.findIndex((x) => x.item.id === id)

      const basketItem =
        serviceIndex > -1
          ? newItems.services[serviceIndex]
          : productIndex > -1
          ? newItems.products[productIndex]
          : null

      if (basketItem) {
        try {
          const option = basketItem.option
            ? basketItem.item.pricing.find(
                (p) => p.data.id === basketItem.option
              )
            : basketItem.item.pricing[0]
          const subOption =
            basketItem.subOption && option
              ? option.data.options.find(
                  (o) => o.data.id === basketItem.subOption
                )
              : null

          window.gtag &&
            window.gtag('event', 'remove_from_cart', {
              items: [
                {
                  currency: 'GBP',
                  category: basketItem.item.category.name,
                  item_id: basketItem.item.id,
                  item_name: basketItem.item.title,
                  name: basketItem.item!.title,
                  price:
                    subOption && subOption.data
                      ? subOption.data.price / 100
                      : option && option.data
                      ? option.data.price / 100
                      : 0,
                  quantity: basketItem.quantity,
                  ...basketItem.item,
                },
              ],
              value:
                subOption && subOption.data
                  ? subOption.data.price / 100
                  : option && option.data
                  ? option.data.price / 100
                  : 0,
              currency: 'GBP',
            })

          try {
            // @ts-ignore
            window.FS &&
              // @ts-ignore
              window.FS.event('remove_from_cart', {
                items: [{ name: basketItem.item.title, ...basketItem }],
                value:
                  subOption && subOption.data
                    ? subOption.data.price / 100
                    : option && option.data
                    ? option.data.price / 100
                    : 0,
                currency: 'GBP',
              })
          } catch (err) {
            console.log(err)
          }

          intercomEvent('remove-from-cart', {
            'page-title': document.title,
            page: window.location.pathname,
            value:
              subOption && subOption.data
                ? subOption.data.price / 100
                : option && option.data
                ? option.data.price / 100
                : 0,
            service: basketItem.item.title,
          })

          // @ts-ignore
          window.heap &&
            // @ts-ignore
            window.heap.track('Remove from Cart', {
              items: [{ id: basketItem.item.id, name: basketItem.item.title }],
              value:
                subOption && subOption.data
                  ? subOption.data.price / 100
                  : option && option.data
                  ? option.data.price / 100
                  : 0,
              currency: 'GBP',
            })
        } catch (err) {
          console.log(err)
          Sentry.captureException(err)
        }
      }

      if (serviceIndex > -1) {
        newItems.services.splice(serviceIndex, 1)
      }

      if (productIndex > -1) {
        newItems.products.splice(productIndex, 1)
      }

      persist(newItems)

      setState((s) => ({
        ...s,
        ...basketContents(newItems, state.discount, state.shipping),
        items: newItems,
      }))

      document.body.dispatchEvent(
        new CustomEvent('items:update', {
          detail: newItems,
        })
      )
    },
    [state]
  )

  const setOption = useCallback(
    (id: string, index?: number) => {
      let item = null
      if (index) {
        item = index
      } else {
        item = state.items.services.findIndex(
          (x) => x.item.pricing.findIndex((y) => y.data.id === id) > -1
        )
      }

      const newItems = {
        services: [...state.items.services],
        products: [...state.items.products],
      }

      newItems.services[item].option = id

      persist(newItems)

      setState((s) => ({
        ...s,
        ...basketContents(newItems, state.discount, state.shipping),
        items: newItems,
      }))
    },
    [state.discount, state.items.products, state.items.services]
  )

  const setServiceItemDetails = useCallback(
    (id: string, data: ServiceBasketItem, index?: number) => {
      let item = null

      if (index) {
        item = index
      } else {
        item = state.items.services.findIndex((x) => x.item.id === id)
      }

      const newItems = {
        services: [...state.items.services],
        products: [...state.items.products],
      }
      newItems.services[item] = data

      persist(newItems)

      setState((s) => ({
        ...s,
        ...basketContents(newItems, state.discount, state.shipping),
        items: newItems,
      }))

      document.body.dispatchEvent(
        new CustomEvent('items:update', {
          detail: newItems,
        })
      )
    },
    [state.discount, state.shipping, state.items.products, state.items.services]
  )

  const setSubOption = useCallback(
    (option: string, id: string, index?: number) => {
      let item = null

      if (index) {
        item = index
      } else {
        item = state.items.services.findIndex(
          (x) => x.item.pricing.findIndex((y) => y.data.id === option) > -1
        )
      }

      const newItems = {
        services: [...state.items.services],
        products: [...state.items.products],
      }

      if (id) {
        newItems.services[item].subOption = id
      } else {
        newItems.services[item].subOption = undefined
      }

      persist(newItems)

      setState((s) => ({
        ...s,
        ...basketContents(newItems, state.discount, state.shipping),
        items: newItems,
      }))
      document.body.dispatchEvent(
        new CustomEvent('items:update', {
          detail: newItems,
        })
      )
    },
    [state.items.products, state.items.services]
  )

  const setProductQuantity = useCallback(
    (id: string, quantity: number) => {
      const item = state.items.products.findIndex((x) => x.item.id === id)
      const newItems = {
        services: [...state.items.services],
        products: [...state.items.products],
      }

      newItems.products[item].quantity = quantity

      persist(newItems)

      setState((s) => ({
        ...s,
        ...basketContents(newItems, state.discount, state.shipping),
        items: newItems,
      }))
      document.body.dispatchEvent(
        new CustomEvent('items:update', {
          detail: newItems,
        })
      )
    },
    [state.items.products, state.items.services]
  )

  const setDiscount = useCallback(
    (discount: Discount) => {
      setState((s) => ({
        ...s,
        discount,
        ...basketContents(s.items, discount, state.shipping),
      }))
      document.body.dispatchEvent(
        new CustomEvent('discount:update', {
          detail: discount,
        })
      )
    },
    [state]
  )

  const setShipping = useCallback(
    (shipping: any) => {
      setState((s) => ({
        ...s,
        shipping,
        ...basketContents(s.items, state.discount, shipping),
      }))
    },
    [state]
  )

  const hideNotify = useCallback(() => {
    setState((s) => ({
      ...s,
      notify: false,
    }))
  }, [state])

  const displayBasket = useCallback(() => {
    setState((s) => ({ ...s, show: !state.show }))
  }, [state.show])

  const empty = useCallback((paymentResult?: PaymentIntent) => {
    setState({
      show: false,
      open: false,
      notify: false,
      discount: null,
      discountTotal: 0,
      shipping: null,
      ...basketContents(
        {
          services: [],
          products: [],
        },
        null,
        null
      ),
      items: {
        services: [],
        products: [],
      },
      paymentResult: paymentResult || null,
      processingPayment: false,
      paymentError: false,
    })
    persist({ services: [], products: [] })
  }, [])

  const membersForServices = useCallback(() => {
    if (!teamData || !state.availibility) return []
    const basketIds = [...state.items.services.map((i) => i.item.id)]
    const set = teamData.team.filter((x) => {
      return (
        state.availibility[x.user.id] &&
        x.user.services.filter((t) => basketIds.includes(t.id)).length > 0
      )
    })
    return set
  }, [state.items.services, state.availibility, teamData])

  const handleSubmission = useCallback(
    async (
      checkoutState: any,
      stripe: Stripe,
      elements: StripeElements,
      newToken: null | string,
      newProfileId: null | string,
      ev: PaymentRequestPaymentMethodEvent
    ) => {
      setState((s) => ({
        ...s,
        paymentError: false,
        processingPayment: true,
      }))

      const appointment: { data: NewAppointment[] } = {
        data: [],
      }

      const appointmentItem: NewAppointment = {
        metadata: {
          ...(sourceParams || {}),
        },
        entities: {
          data: [],
        },
        payments: {
          data: [],
        },
      }

      let entities: AppointmentEntity[] = []
      try {
        entities = state.items.services.reduce((a, x, currentIndex) => {
          const collection = [...a]

          const previous = a[a.length - 1]
          const start = previous
            ? new Date(previous.end)
            : checkoutState.selectedTime!

          const attr = x.item.pricing.find((p) => p.data.id === x.option)
          const subAttr = x.subOption
            ? attr.data.options.find((p) => p.data.id === x.subOption)
            : null

          const duration = subAttr
            ? Number(subAttr.data.attributes.duration)
            : Number(attr.data.attributes.duration)

          const end = start && duration > 0 ? addMinutes(start, duration) : null

          collection.push({
            employee: checkoutState.member!.user.id,
            service: x.item.id,
            pricing_option: x.option,
            pricing_sub_option: x.subOption || null,
            start: start && currentIndex < 2 && formatISO(start),
            end: end && currentIndex < 2 && formatISO(end),
            status: 'new',
          })

          const sessions =
            subAttr && subAttr.data.attributes
              ? Number(subAttr.data.attributes.sessions)
              : attr && attr.data.attributes
              ? Number(attr.data.attributes.sessions)
              : null

          if (sessions && sessions > 1) {
            for (let index = 1; index < sessions; index++) {
              collection.push({
                employee: checkoutState.member!.user.id,
                service: x.item.id,
                pricing_option: x.option,
                pricing_sub_option: x.subOption || null,
                status: 'course_session',
              })
            }
          }

          return collection
        }, [])
      } catch (err) {
        console.log('entitiy err', err)
        Sentry.captureException(err)
      }

      appointmentItem.entities.data = entities

      appointment.data.push(appointmentItem)

      const billing_details: PaymentMethodCreateParams.BillingDetails = {
        name: `${checkoutState.details.name} ${checkoutState.details.lastName}`,
        email: checkoutState.details.email as string,
        phone: checkoutState.details.phoneNumber as string,
      }
      if (checkoutState.details.addressLineOne) {
        billing_details.address = {}
        billing_details.address.line1 = checkoutState.details.addressLineOne
      }
      if (checkoutState.details.addressLineTwo) {
        if (!billing_details.address) billing_details.address = {}
        billing_details.address.line2 = checkoutState.details.addressLineTwo
      }
      if (checkoutState.details.city) {
        if (!billing_details.address) billing_details.address = {}
        billing_details.address.city = checkoutState.details.city
      }
      if (checkoutState.details.postcode) {
        if (!billing_details.address) billing_details.address = {}
        billing_details.address.postal_code = checkoutState.details.postcode
      }

      const intent = checkoutState.intents.deposit!

      console.dir(appointment, { depth: null })
      // return false
      const paymentMethod = ev
        ? {
            payment_method: ev.paymentMethod.id,
          }
        : {
            payment_method: {
              card: elements.getElement(CardNumberElement)!,
              billing_details,
            },
          }

      const { error, paymentIntent: p } = await stripe!.confirmCardPayment(
        intent.client_secret,
        paymentMethod,
        {
          handleActions: ev ? false : true,
        }
      )

      if (error) {
        ev && ev.complete('fail')
        setState((s) => ({
          ...s,
          paymentError: error,
          processingPayment: false,
        }))
        try {
          Sentry.captureException(error)
          // @ts-ignore
          window.heap &&
            // @ts-ignore
            window.heap.track('Booking Error', {
              method: 'Stripe Error',
              ...error,
            })
        } catch (err) {
          Sentry.captureException(err)
          console.log('err', err)
        }
        console.log('error', error)
        return false
      }

      ev && ev.complete('success')
      let pq: PaymentIntent | null = null

      if (p.status === 'requires_action') {
        const { error, paymentIntent: pqr } = await stripe.confirmCardPayment(
          intent.client_secret
        )
        pq = pqr
        if (error) {
          setState((s) => ({
            ...s,
            paymentError: error,
            processingPayment: false,
          }))
          return false
        }
      }

      if (
        (pq && pq.status === 'succeeded') ||
        (p && p.status === 'succeeded')
      ) {
        try {
          const itemsTotal = state.items.services.reduce(
            (a: any, i: BasketItem) => {
              const opt = i.item.pricing.find((x) => x.data.id === i.option)
              const sub = i.subOption
                ? opt.data.options.find((x) => x.data.id === i.subOption)
                : null
              if (sub) {
                return a + sub.data.price
              }
              if (opt) {
                return a + opt.data.price
              }
              return a
            },
            0
          )

          recordConversion({
            send_to: Conversion.purchase,
            // @ts-ignore
            value: itemsTotal / 100,
            currency: 'GBP',
          })

          window.gtag &&
            window.gtag('event', 'purchase', {
              transaction_id: pq ? pq.id : p.id,
              affiliation: 'Soluna London',
              value: itemsTotal / 100,
              currency: 'GBP',
              tax: 0.2 * (itemsTotal / 100),
              shipping: 0,
              items: state.items.services.map((i: BasketItem) => {
                const opt = i.item.pricing.find((x) => x.data.id === i.option)
                const sub = i.subOption
                  ? opt.data.options.find((x) => x.data.id === i.subOption)
                  : null
                return {
                  id: i.item.id,
                  name: i.item.title,
                  quantity: i.quantity,
                  price: sub ? sub.data.price / 100 : opt.data.price / 100,
                }
              }),
            })

          try {
            // @ts-ignore
            window.FS &&
              // @ts-ignore
              window.FS.event('purchase', {
                transaction_id: pq ? pq.id : p.id,
                affiliation: 'Soluna London',
                value: itemsTotal / 100,
                currency: 'GBP',
                tax: 0.2 * (itemsTotal / 100),
                shipping: 0,
                items: state.items.services.map((i: BasketItem) => {
                  const opt = i.item.pricing.find((x) => x.data.id === i.option)
                  const sub = i.subOption
                    ? opt.data.options.find((x) => x.data.id === i.subOption)
                    : null
                  return {
                    id: i.item.id,
                    name: i.item.title,
                    quantity: i.quantity,
                    price: sub ? sub.data.price / 100 : opt.data.price / 100,
                  }
                }),
              })
          } catch (err) {
            console.log(err)
          }

          // @ts-ignore
          window.heap &&
            // @ts-ignore
            window.heap.track('Purchase', {
              value: itemsTotal / 100,
              transaction: pq ? pq.id : p.id,
              discountValue: 0,
              code: 0,
            })

          intercomEvent('purchase', {
            'page-title': document.title,
            page: window.location.pathname,
            value: itemsTotal / 100,
            'transaction-id': pq ? pq.id : p.id,
            affiliation: 'Soluna London',
            ...state.items.services.reduce((a: any, i: BasketItem) => {
              return {
                ...a,
                [`${i.item.title}`]: i.quantity,
              }
            }, {}),
          })
        } catch (err) {
          console.log(err)
          Sentry.captureException(err)
        }

        appointment.data[0].payments.data = [
          {
            paymentByPayment: {
              data: {
                amount: pq ? pq.amount : p.amount,
                transaction_id: pq ? pq.id : p.id,
              },
            },
          },
        ]

        try {
          const cl = new ApolloClient({
            cache: new InMemoryCache(),
            uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
            credentials: 'include',
            headers: {
              authorization: `Bearer ${newToken || token}`,
              'x-hasura-organization-id':
                process.env.NEXT_PUBLIC_ORGANIZATION_ID,
            },
          })

          const { data: newAppointmentData } = await cl.mutate({
            mutation: newAppointment,
            variables: appointment,
          })

          try {
            if (checkoutState.details.phoneNumber) {
              const en = checkoutState.details.phoneNumber.startsWith('0')
              const formatted = en
                ? `+44${checkoutState.details.phoneNumber.replace(/^0+/, '')}`
                : checkoutState.details.phoneNumber
              if (
                !profile ||
                !profile.phoneNumber ||
                (profile &&
                  profile.phoneNumber &&
                  profile.phoneNumber.length === 0) ||
                (profile &&
                  profile.phoneNumber &&
                  profile.phoneNumber !== formatted)
              ) {
                cl.mutate({
                  mutation: addContactNumber,
                  variables: {
                    number: formatted,
                    id: newProfileId || profile.id,
                  },
                })
              }
            }
          } catch (err) {
            Sentry.captureException(err)
            console.log('Error adding contact number', err)
          }

          if (checkoutState.details.addressLineOne) {
            try {
              client.mutate({
                mutation: addAddress,
                variables: {
                  address: {
                    addressByAddress: {
                      data: {
                        data: {
                          addressLineOne: checkoutState.details.addressLineOne,
                          addressLineTwo: checkoutState.details.addressLineTwo,
                          city: checkoutState.details.city,
                          postcode: checkoutState.details.postcode,
                        },
                      },
                    },
                  },
                },
              })
            } catch (err) {
              Sentry.captureException(err)
              console.log('Error adding user address', err)
            }
          }

          if (sourceParams) {
            try {
              localStorage.removeItem('cl.p')
              setContext((s) => ({ ...s, sourceParams: null }))
            } catch (err) {
              console.log(err)
              Sentry.captureException(err)
            }
          }

          if (newAppointmentData) {
            router.push('/account/?complete=true')
            setTimeout(() => empty(p), 2000)
            return true
          }
        } catch (err) {
          Sentry.captureException(err)
          console.log('ERR', err)
          setState((s) => ({
            ...s,
            paymentError: false,
            processingPayment: false,
          }))
        }
      } else {
        console.log('payment error')
        setState((s) => ({
          ...s,
          paymentError: error,
          processingPayment: false,
        }))
        return false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state, profile, empty, claims, client]
  )

  const setOpen = (open) => {
    setState((s) => ({ ...s, open }))
  }

  const value: BasketContextValue = useMemo(
    () => ({
      ...state,
      addItem,
      removeItem,
      setOption,
      displayBasket,
      setSubOption,
      empty,
      membersForServices,
      loadAvailibility,
      setServiceItemDetails,
      setProductQuantity,
      setOpen,
      setDiscount,
      setShipping,
      // @ts-ignore
      resources: availibilityData && availibilityData.resource,
      // @ts-ignore
      blocks: availibilityData && availibilityData.blocks,
      team:
        teamData &&
        teamData.team &&
        teamData.team.filter((t) => t.user.services.length > 0),
      handleSubmission,
      availibilityLoading,
      hideNotify,
      setDuration: (i: number) => {
        setState((s) => ({
          ...s,
          duration: i,
        }))
      },
      setLoading: () => {
        setState((s) => ({
          ...s,
          paymentError: false,
          processingPayment: true,
        }))
      },
    }),
    [
      state,
      addItem,
      removeItem,
      setOption,
      displayBasket,
      setSubOption,
      empty,
      membersForServices,
      loadAvailibility,
      setServiceItemDetails,
      setProductQuantity,
      setDiscount,
      setShipping,
      teamData,
      handleSubmission,
      availibilityLoading,
      hideNotify,
    ]
  )

  return (
    <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
  )
}

export const BasketProvider = Basket

export const useBasket = () => useContext(BasketContext)
