import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Conversion, recordConversion } from './conversions'
import { BasketItems } from '../providers/basket'

export const intercomEvent = (label: string, data = {}) => {
  // @ts-ignore
  window.Intercom && window.Intercom('trackEvent', label, data)
}

export const track = (action, params, fields?) => {
  window.gtag && window.gtag('event', action, {
    ...params
  })
  const h: { [key: string]: any } = {
    page: window.location.href
  }
  if (params.event_label) {
    h.label = params.event_label
  }
  // @ts-ignore
  window.heap && window.heap.track(action, h)
  if (fields) {
    // @ts-ignore
    window.heap && window.heap.addUserProperties({ ...fields })
  }
  // @ts-ignore
  if (window.FS) {
    try {
      // @ts-ignore
      window.FS.event(action, {
        ...params,
        ...(fields || {})
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function usePageViews() {
  let router = useRouter()

  useEffect(() => {
    window.gtag && window.gtag('config', 'AW-771019031', { ' allow_enhanced_conversions': true });
    window.gtag && window.gtag('config', 'AW-677323245', { ' allow_enhanced_conversions': true });
    window.gtag && window.gtag('set', 'allow_google_signals', true)
    window.gtag && window.gtag('set', 'allow_ad_personalization_signals', true);

    if (window.gtag) {
      setTimeout(() => {
        window.gtag('config', 'AW-677323245/Xqs5CMG_8fcBEO3D_MIC', {
          phone_conversion_number: '0333 016 3500',
          phone_conversion_css_class: 'phone-number',
        });
      }, 1000)
    }

    (function () {
      var scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight
      var quarterHeight = scrollHeight / 4
      var quarters = 0
      var scrollDistance, divisible, scrollPercent
      document.addEventListener('scroll', function () {
        scrollDistance =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
            // @ts-ignore
          ).scrollTop
        divisible = Math.trunc(scrollDistance / quarterHeight)
        if (quarters < divisible && divisible !== Infinity) {
          scrollPercent = divisible * 25
          // @ts-ignore
          window.heap &&
            // @ts-ignore
            heap.track('Scroll Depth', {
              percent: scrollPercent,
            })
          quarters++
        }
      })
    })()

    // @ts-ignore
    // window.KlarnaOnsiteService && window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    // @ts-ignore
    // setTimeout(() => window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' }), 3000);

    try {
      window.addEventListener("klaviyoForms", function (e: any) {
        try {
          if (e.detail.type == 'open' || e.detail.type == 'embedOpen') {
            // @ts-ignore
            klaviyo && klaviyo.push(['track', 'Viewed Form - Tracked Profile', {
              'formId': e.detail.formId
            }]);
          }

          if (e.detail.type == 'submit' || e.detail.type == 'redirectedToUrl') {
            // @ts-ignore
            klaviyo && klaviyo.push(['track', 'Submitted Form - Tracked Profile', {
              'formId': e.detail.formId
            }]);
          }

          if (e.detail.type === 'submit') {
            // recordConversion({
            //   send_to: Conversion.newsletter,
            //   // @ts-ignore
            //   value: 1,
            //   currency: 'GBP',
            // })
            // window.gtag && window.gtag('event', 'Klaviyo form', {
            //   'event_category': "sign_up",
            //   'event_label': "form_submit",
            //   'value': 1
            // })

            if (e.detail.metaData && e.detail.metaData.$email) {
              // @ts-ignore
              window.heap && window.heap.addUserProperties({
                email: e.detail.metaData.$email,
              })
              // @ts-ignore
              window && window.Intercom && window.Intercom('boot', {
                app_id: 'rn8g2h3e',
                email: e.detail.metaData.$email,
              })
              Sentry.configureScope(function (scope) {
                scope.setUser({
                  email: e.detail.metaData.$email,
                })
              })
            }
          }
        } catch (err) {
          console.log(err)
        }
      });
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    window.gtag &&
      window.gtag('config', 'UA-126290103-1', {
        page_path: router.asPath,
      })
    window.gtag && window.gtag('config', 'AW-771019031', { ' allow_enhanced_conversions': true });
    window.gtag && window.gtag('config', 'AW-677323245', { ' allow_enhanced_conversions': true });

    window.gtag && window.gtag('set', 'allow_google_signals', true)
    window.gtag && window.gtag('set', 'allow_ad_personalization_signals', true);
    // @ts-ignore
    window.fbq && window.fbq('track', 'PageView')
    // @ts-ignore
    window.Intercom && window.Intercom("update", { last_request_at: parseInt((new Date()).getTime() / 1000) })
    // @ts-ignore
    // window.KlarnaOnsiteService && window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    // @ts-ignore
    // setTimeout(() => window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' }), 3000);
  }, [router.pathname])
}

export const trackAction = (type: string) => {
  // @ts-ignore
  track('generate_lead', {
    event_label: type,
    event_value: 0,
    currency: "GBP",
    value: 0
  })
  intercomEvent(`${type}-action`, {
    'page-title': document.title,
    page: window.location.pathname
  })
}

export const itemsToGA = (items: BasketItems) => {
  const collection = [];
  items.services.forEach(service => {
    const option = service.option
      ? service.item.pricing.find(p => p.data.id === service.option)
      : service.item.pricing[0];
    const subOption = service.subOption && option
      ? option.data.options.find(o => o.data.id === service.subOption)
      : null;

    collection.push({
      item_id: service.item.id,
      item_name: service.item.title,
      item_brand: 'Soluna London',
      item_category: service.item.category.name,
      item_variant: subOption && subOption.data
        ? subOption.data.label
        : option && option.data
          ? option.data.label
          : '',
      price: subOption && subOption.data
        ? subOption.data.price / 100
        : option && option.data
          ? option.data.price / 100
          : 0,
      quantity: service.quantity,
      location_id: 'ChIJ2QrQoCWr2EcRQo6tGJ8wSHY'
    })
  })

  items.products.forEach(product => {
    const option = product.option
      ? product.item.pricing.find(p => p.data.id === product.option)
      : product.item.pricing[0];
    const subOption = product.subOption && option
      ? option.data.options.find(o => o.data.id === product.subOption)
      : null;

    collection.push({
      item_id: product.item.id,
      item_name: product.item.title,
      item_brand: 'SkinCeuticals',
      item_category: product.item.category.name,
      item_variant: subOption && subOption.data
        ? subOption.data.label
        : option && option.data
          ? option.data.label
          : '',
      price: subOption && subOption.data
        ? subOption.data.price / 100
        : option && option.data
          ? option.data.price / 100
          : 0,
      quantity: product.quantity,
      location_id: 'ChIJ2QrQoCWr2EcRQo6tGJ8wSHY'
    })
  })

  return collection;
}

export default {}
